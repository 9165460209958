<template>
	<div class="page">
		<div class="p-footer h-end">
			<button class="btn auto fill ml-3"
			        @click="toIndex()">返回首页
			</button>
		</div>

		<div class="select-object">
			<div class="select-object__title flex">
				<div class="fs-14 b fs-16 the-color-black">采集对象</div>
				<u class="color-primary flex-right fs-12" @click="toGroup"> {{
						collectionObjectData.groupName ? '更改' : '立即设置'
					}}</u>
			</div>
			<div class="select-object__group">
				<template v-if="collectionObjectData.groupName">
					<div class="group-sign flex">
						<img class="group-img" :src="collectionObjectData.groupHeadImgUrl">
						<div class="group-name ml-2 fs-14 b text-flow">
							{{ collectionObjectData.groupName ? collectionObjectData.groupName : '未命名' }}
						</div>
					</div>
					<!--        <div class="group-personnel mt-4 flex  wrap h-between">-->
					<div class="group-personnel mt-4  h-between" v-if="isAppoint">
						<template v-for="i in collectionObjectData.teachers">
							<div class="flex column group-personnel__item">
								<img class="robotAvatar group-personnel__item--img" :src="i.headImgUrl">
								<p class="b mt-1 fs-12 text-ellipsis text-center group-personnel__item--name">{{ i.nickName }}</p>
							</div>
						</template>
					</div>
				</template>

				<div class="no-object" v-else>
					<div class="flex column mt-3">
						<div class="relative">
							<img class="robotAvatar" :src="require('../../assets/avatar.png')">
						</div>
						<div class="fs-12 color-gray mt-1">暂未设置</div>
					</div>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ApiGetTeacher } from '@/apis/FollowLive'

export default {
	name: 'Index',
	data() {
		return {
			// 是否指定人选
			isAppoint: false,
			loading: false,
			robot: {
				headImgUrl: '',
				nickName: '暂未设置',
				groupName: '暂未设置'
			},
			pageIndex: 1,
			pageSize: 1000,
			groupList: [],
			show: false,
			logoutDialog: false,

			checked: true,
			wxType: 1

		}
	},
	created() {
		this.getCollectionData()
	},
	computed: {
		...mapGetters(['collectionObjectData'])
	},
	methods: {
		async getCollectionData() {
			const res = await ApiGetTeacher()
			if (res) {
				this.isAppoint = res.routeType === 0
				this.$store.commit('setCollectionObjectData', res)
			} else {
				this.$store.commit('resetCollectionObjectData')
			}
		},

		toGroup() {
			const _this = this
			_this.$router.push('/selectGroupGather')
		},
		toIndex() {
			const _this = this
			_this.$router.replace('/index')
		}
	}
}
</script>

<style scoped lang="scss">
@import "index";
</style>
