import { myAxios } from '@/utils/request'
// 获取讲师 相当于微信群信息
const ApiGetTeacher =  (par = {}) => {
  return myAxios.get('/FollowLive/GetTeacher')
}

// 设置采集对象
const ApiSetTeacher =  (data = {}) => {
  return myAxios.post('/FollowLive/SetTeacher', {
    // 机器人wxid
    robotWxId: data.robotWxId,
    // 群Id
    chatroomId: data.chatroomId,
    // 采集对象集合
    teacherWxIds: data.teacherWxIds,
    // 0讲师模式,1全群模式
    routeType: data.routeType
  })
}

const ApiGetChatrooms = ( data = {} ) => {
  return myAxios.post( '/FollowLive/GetChatrooms', {
    pageIndex: data.pageIndex,
    pageSize: data.pageSize ,
    // 排序字段名称
    sortName: data.sortName,
    sortOrder: 0,
    // 机器人编号
    robotWxId: data.robotWxId ,
    // 群名称
    groupName: data.groupName ,
  
  })
}

// 查询群成员
const ApiQueryChatroomMembers =  (data = {}) => {
  return myAxios.post('/FollowLive/QueryChatroomMembers', {
    // 机器人wxid
    robotWxId: data.robotWxId,
    // 群Id
    chatroomId: data.chatroomId,
    // 模糊查询
    likeName: data.likeName,
  })
}

export {
  // 获取更发素材来源
  ApiGetTeacher,
  // 设置采集对象
  ApiSetTeacher,
  // // 设置跟发群
  // ApiSetFollowLiveChatroom,
  // 查询群成员
  ApiQueryChatroomMembers,
  // 获取群组信息
  ApiGetChatrooms
}
